import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { StyledTitle, TextContent } from "../../styles/StyledComponents";
import { useDetectDevice } from "../../util/hooks";
import FooterNav from "./FooterNav";

const FooterWrapper = styled.footer`
  flex-shrink: 0;
  display: flex;
  padding: 10px;
  background-color: ${colors.blue};
  justify-content: center;
`;

const BannerTitle = styled(StyledTitle)`
  font-family: "Lato";
  font-size: ${({ size }) => (size ? size : "15pt")};
  margin-top: 5px;
  padding-left: 0px;
  color: ${({ color }) => (color ? color : colors.orange)};
`;

const FooterBlock = styled.div`
  margin-top: 10px;
  margin-bottom: 15px;
  flex-direction: ${({ mobile }) => (mobile ? "row" : "column")};
`;
const FooterText = styled(TextContent)`
  font-size: ${({ size }) => (size ? size : "12")}pt;
  line-height: 1.2;

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`;

const Footer = () => {
  const { isTablet } = useDetectDevice();
  let checkMobile = false;
  if (isTablet) {
    checkMobile = true;
  }
  return (
    <FooterWrapper>
      <FooterBlock>
        <FooterBlock>
          {checkMobile ? null : (
            <BannerTitle weight={700}>Marine Debris Tracker</BannerTitle>
          )}
        </FooterBlock>
        <FooterBlock>
          <FooterText color="white">
            Whether you’re a non-profit organization or a concerned citizen, we
            want to support your data collection efforts. Questions or concerns?
            Send us an email at debristracker101@gmail.com
          </FooterText>
        </FooterBlock>
      </FooterBlock>
      <FooterBlock mobile={checkMobile}>
        <FooterNav />
      </FooterBlock>
    </FooterWrapper>
  );
};

export default Footer;
