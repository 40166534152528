import { Link } from "gatsby";
import React from "react";
import { useContext } from "react";
import styled from "styled-components";
import { DebrisTrackerContext } from "../../context/DebrisTrackerContext";
import { colors } from "../../styles/colors";
import { navButtonTest } from "../../util/globals";
import { useDetectDevice } from "../../util/hooks";
import {
  LoginButtonFooter,
  LogoutButtonFooter,
} from "../AuthComponents/LoginButton/LoginButton";
import {
  MyAccountButtonFooter,
  SignUpButtonFooter,
} from "../AuthComponents/SignUpButton/SignUpButton";

const NavBtnWrapperFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr 1fr;
`;

const NavBtnLinkFooter = styled(Link)`
  text-decoration: none;
  grid-column: 1;
  color: ${colors.white};
  margin: 10px;
  font-size: 15pt;
`;

const NavButtonsFooter = () => {
  const { isTablet } = useDetectDevice();
  const { user } = useContext(DebrisTrackerContext);
  if (isTablet) {
    return <NavBtnWrapperFooter></NavBtnWrapperFooter>;
  }
  return (
    <NavBtnWrapperFooter>
      {navButtonTest.map((btn, i) => {
        return (
          <NavBtnLinkFooter key={i} to={btn.url}>
            {btn.title}
          </NavBtnLinkFooter>
        );
      })}
      {user && user.id ? (
        <>
          <LogoutButtonFooter />
          <MyAccountButtonFooter />
        </>
      ) : (
        <>
          <SignUpButtonFooter />
          <LoginButtonFooter />
        </>
      )}
    </NavBtnWrapperFooter>
  );
};

const FooterNav = () => {
  return <NavButtonsFooter />;
};

export default FooterNav;
