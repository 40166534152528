import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { colors } from "../../../styles/colors";

const SignUpBtn = styled(Link)`
  text-decoration: none;
  color: ${colors.white};
  margin: 10px;
  font-size: 15pt;
  background-color: ${colors.orange};
  padding: 8px 13px;
  opacity: 0.95;
  &:hover {
    opacity: 1;
  }
`;

const SignUpBtnFooter = styled(Link)`
  text-decoration: none;
  color: ${colors.white};
  margin: 10px;
  font-size: 15pt;
  grid-column: 2;
  grid-row: 2;
`;

export const MyAccountButton = () => {
  return <SignUpBtn to="/dashboard/user">My Account</SignUpBtn>;
};

export const MyAccountButtonFooter = () => {
  return <SignUpBtnFooter to="/dashboard/user">My Account</SignUpBtnFooter>;
};

export const SignUpButton = () => {
  return <SignUpBtn to="/sign-up">Sign Up</SignUpBtn>;
};

export const SignUpButtonFooter = () => {
  return <SignUpBtnFooter to="/sign-up">Sign Up</SignUpBtnFooter>;
};
