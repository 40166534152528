import React, { useContext } from "react";
import { DebrisTrackerContext } from "../../../context/DebrisTrackerContext";
import { Nav } from "../NavbarComponents";
import { navButtonTest } from "../../../util/globals";
import styled from "styled-components";
import NavButton from "../NavButton/NavButton";
import {
  MyAccountButton,
  SignUpButton,
} from "../../AuthComponents/SignUpButton/SignUpButton";
import { LoginButton } from "../../AuthComponents/LoginButton/LoginButton";
import { colors } from "../../../styles/colors";

const VerticalBar = styled.hr`
  opacity: 0.5;
  width: 1px;
  height: 30px;
  border: medium none;
  background: ${colors.lightBlue} none repeat scroll 0% 0%;
  margin-left: 12px;
  margin-right: 12px;
`;

export const NavbarButtons = () => {
  const { user } = useContext(DebrisTrackerContext);
  return (
    <Nav.Buttons>
      {navButtonTest.map((btn, i) => {
        return (
          <NavButton key={i} to={btn.url} title={btn.title} items={btn.items} />
        );
      })}
      <VerticalBar />
      {user && user.id ? (
        <>
          <MyAccountButton />
        </>
      ) : (
        <>
          <LoginButton />
          <SignUpButton />
        </>
      )}
    </Nav.Buttons>
  );
};
