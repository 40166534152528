import React from "react";
import { Nav } from "../NavbarComponents";
import { Link } from "gatsby";
import NavImg from "../../../images/MarineDebrisTracker_Logo_Horizontal2.svg";
import { NavbarButtons } from "./NavbarButtons";

const Navbar = () => {
  return (
    <Nav.Wrapper>
      <Nav.Logo>
        <Link to="/">
          <img src={NavImg} width={350} alt="Marine Debris Tracker" />
        </Link>
      </Nav.Logo>
      <NavbarButtons />
    </Nav.Wrapper>
  );
};

export default Navbar;
