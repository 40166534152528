export const navButtonTest = [
  {
    title: "Data",
    url: "/data",
    items: [
      { title: "ETAP", url: "/etap" },
    ],
  },
  {
    title: "News",
    url: "/news",
  },
  {
    title: "Resources",
    url: "/resources",
    items: [
      { title: "Events", url: "/events" },
      // { title: "Guides", url: "/guides" },
    ],
  },
  {
    title: "Privacy Policy",
    url: "/privacy-policy",
  },
];

export const validateEmail = (email) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
};
