import * as React from "react";
import "./global.css";
import Footer from "../Footer/Footer";
import Navbar from "../Nav/Navbar/Navbar";
import MobileNavbar from "../Nav/MobileNavbar/MobileNavbar";
import styled from "styled-components";
import { useDetectDevice } from "../../util/hooks";

interface LayoutType {
  children?: any;
  vertical: boolean;
  color?: string;
}

const MainContent = styled.main`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? "column" : "row")};
  min-height: calc(100vh - 177px - 100px);
  background-color: ${({ color }) => color};
`;

const Layout = ({ children, vertical, color }: LayoutType) => {
  const { isTablet } = useDetectDevice();

  return (
    <>
      <link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.0.1/dist/leaflet.css"
      />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap"
        rel="stylesheet"
      />
      {isTablet ? <MobileNavbar /> : <Navbar />}
      <MainContent isTablet={isTablet} vertical={vertical} color={color}>
        {children}
      </MainContent>
      <Footer />
    </>
  );
};

export default Layout;
