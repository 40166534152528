import { Link } from "gatsby";
import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const NavBtnContainer = styled.div`
  position: relative;
`;

const NavBtnLink = styled(Link)`
  text-decoration: none;
  color: ${({ selected }) => (selected ? colors.orange : colors.lightBlue)};
  font-size: 15pt;
  z-index: 4001;
  margin: 10px;
`;

const NavBtnWrapper = styled.div``;

const NavDropdownLink = styled(Link)`
  text-decoration: none;
  padding: 13px;
  font-size: 20px;
  transition: 250ms all;
  color: ${colors.lightBlue};
`;

const NavDropdownWrapper = styled.div`
  position: absolute;
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  background-color: ${colors.white};
  z-index: 4001;
  width: 100%;
`;

const NavButton = ({
  to,
  title,
  items,
  selected,
}: {
  to: string;
  title: string;
  items?: any[];
  selected?: boolean;
}) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <NavBtnContainer
      onMouseEnter={() => {
        setShow(true);
      }}
      onMouseLeave={() => {
        setShow(false);
      }}
    >
      <NavBtnWrapper>
        <NavBtnLink
          selected={selected}
          style={items ? { marginRight: "0px" } : {}}
          to={to}
        >
          {title}
        </NavBtnLink>
        {items && (
          <FontAwesomeIcon
            style={{ marginLeft: "5px", marginRight: "10px" }}
            color={colors.lightBlue}
            icon={faAngleDown}
          />
        )}
      </NavBtnWrapper>
      {items && (
        <NavDropdownWrapper show={show}>
          {items.map((item, i) => {
            return (
              <NavDropdownLink selected={selected} key={i} to={item.url}>
                {item.title}
              </NavDropdownLink>
            );
          })}
        </NavDropdownWrapper>
      )}
    </NavBtnContainer>
  );
};

export default NavButton;
