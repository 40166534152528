import React from "react";
import { useContext } from "react";
import { DebrisTrackerContext } from "../../../context/DebrisTrackerContext";
import { navButtonTest } from "../../../util/globals";
import { MobileNav } from "../NavbarComponents";
import NavButton from "../NavButton/NavButton";

const MobileNavButton = ({
  to,
  title,
}: {
  to: string;
  title: string;
  items?: any[];
}) => {
  return (
    <MobileNav.ButtonWrapper>
      <MobileNav.Button>
        <NavButton to={to} title={title} />
      </MobileNav.Button>
    </MobileNav.ButtonWrapper>
  );
};

export const MobileNavbarButtons = () => {
  const { user } = useContext(DebrisTrackerContext);

  const navButtons = [
    ...navButtonTest,
    { title: "Events", items: [], url: "/events" },
  ];

  if (!user || !user.id) {
    navButtons.push({ title: "Log In", items: [], url: "/login" });
  }

  return (
    <>
      {navButtons.map((btn, i) => {
        return (
          <MobileNavButton
            key={i}
            to={btn.url}
            title={btn.title}
            items={btn.items}
          />
        );
      })}
    </>
  );
};
