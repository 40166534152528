import { useEffect, useRef, useState } from "react";

export const useIsMounted = () => {
  const ref = useRef<boolean>(false);
  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);
  return ref;
};

export const useDetectDevice = () => {
  const [width, setWidth] = useState(200000);

  const updateSize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    updateSize();

    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  return {
    isDesktop: width > 991,
    isSmallScreen: width <= 991,
    isTablet: width <= 850,
    isLandscape: width <= 600,
    isMobile: width <= 478,
    width: width,
  };
};

export const useWarning = () => {
  const [warning, setWarning] = useState(undefined);
  const createWarning = (text, timeout = 3000) => {
    setWarning(text);
    setTimeout(() => {
      setWarning(undefined);
    }, timeout);
  };
  return [warning, createWarning];
};
