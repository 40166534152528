import { Link } from "gatsby";
import React, { useContext } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/colors";
import Button from "../../Button/Button";
import { navigate } from "gatsby";
import { DebrisTrackerContext } from "../../../context/DebrisTrackerContext";
import { logoutUser } from "../../../mdtApi/loginUser";

const LoginBtn = styled(Link)`
  text-decoration: none;
  color: ${colors.lightBlue};
  margin: 10px;
  font-size: 15pt;
`;

const LoginBtnFooter = styled(Link)`
  text-decoration: none;
  color: ${colors.white};
  margin: 10px;
  font-size: 15pt;
  grid-column: 2;
  grid-row: 1;
`;

export const LoginButton = () => {
  return <LoginBtn to="/login">Log In</LoginBtn>;
};

export const LogoutButton = () => {
  const { user, setUser } = useContext(DebrisTrackerContext);
  return (
    <Button
      btn={true}
      style={{
        backgroundColor: "transparent",
      }}
      onClick={async () => {
        await logoutUser(user.aws_access_token);
        sessionStorage.removeItem("user");
        setUser(undefined);
        navigate("/");
      }}
    >
      Logout
    </Button>
  );
};

export const LogoutButtonFooter = () => {
  const { setUser } = useContext(DebrisTrackerContext);

  return (
    <LoginBtnFooter
      onClick={() => {
        sessionStorage.removeItem("user");
        setUser(undefined);
      }}
      to={"/"}
    >
      Log Out
    </LoginBtnFooter>
  );
};

export const LoginButtonFooter = () => {
  return <LoginBtnFooter to="/login">Log In</LoginBtnFooter>;
};
