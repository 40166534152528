import styled from "styled-components";
import { animated } from "@react-spring/web";
import { colors } from "../../styles/colors";

export const Nav = {
  Wrapper: styled.div`
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 102px;
    box-shadow: 0 6px 20px 0 rgb(113 120 248 / 10%);
  `,
  Logo: styled.div`
    margin: 15px 0px;
  `,
  Buttons: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
};

export const MobileNav = {
  Wrapper: styled.div`
    position: sticky;
    z-index: 50000;
    top: 0;
    height: 75px;
  `,
  BannerWrapper: styled.div`
    display: flex;
    position: absolute;
    flex-direction: row;
    width: 100vw;
    justify-content: space-between;
    height: 75px;
    align-items: center;
    font-weight: 700;
    font-size: 22px;
    color: #536275;
    top: 0;
    background-color: #ffffff;
    box-shadow: 0 6px 20px 0 rgb(113 120 248 / 10%);
  `,
  ItemsWrapper: styled(animated.div)`
    width: 100vw;
    background-color: #ffffff;
    position: absolute;
    top: 75px;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    font-weight: normal;
  `,
  HamburgerWrapper: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 20px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  `,
  Hamburger: styled.div(
    ({ open }) => `
    width: 30px;
    height: 4px;
    background: ${open ? "transparent" : colors.lightBlue};
    border-radius: 5px;
    transition: all 0.5s ease-in-out;

    &:before,
    :after {
      content: "";
      position: absolute;
      width: 30px; 
      height: 4px;
      background: ${open ? colors.orange : colors.lightBlue};
      border-radius: 5px;
      transition: all 0.5s ease-in-out;
    }

    &:before {
      transform: ${open ? "rotate(45deg)" : "translateY(-10px)"};
      opacity:1;
    }

    &:after {
      transform: ${open ? "rotate(-45deg)" : "translateY(10px)"};
      opacity:1;
    }



  `
  ),
  Button: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  ButtonWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    margin-left: 10px;
    font-size: 30px;
    border-bottom: 1px solid #53627530;
  `,
};
