import React from "react";
import { MobileNav } from "../NavbarComponents";

const Hamburger = ({ open, ...rest }) => {
  return (
    <MobileNav.HamburgerWrapper {...rest}>
      <MobileNav.Hamburger open={open} />
    </MobileNav.HamburgerWrapper>
  );
};

export default Hamburger;
