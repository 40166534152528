import styled, { css } from "styled-components";
import React from "react";
import { colors } from "./colors";

export interface TitleProps {
  centered?: boolean;
  weight?: number;
  level?: number;
  children?: any;
  color?: string;
  alt?: boolean;
  style?: any;
  full?: boolean;
  altFont?: boolean;
  size?: string;
  as?: string;
}

export const button = css<{ square?: boolean }>`
  font-size: 18pt;
  align-self: center;
  color: rgb(255, 254, 226);
  background-color: #edbd7f;
  min-width: 120px;
  padding: 10px;
  text-align: center;
  border-radius: ${({ square }) => (square ? "0px" : "15px")};
  opacity: 0.95;
  cursor: pointer;
  &:hover {
    filter: brightness(90%);
  }
`;

export const Emphasis = styled("span")<{ alt?: boolean }>`
  font-family: ${({ alt }) => (alt ? "Lato" : "Bree Serif, sans-serif")};
  color: ${({ color }) => (color ? color : "#536275")};
  font-weight: 700;
`;

export const TextContent = styled("div")<{
  color?: string;
  centered?: boolean;
  size?: string;
}>`
  color: ${({ color }) => (color ? color : "#536275")};
  max-width: 500px;
  font-size: ${({ size }) => (size ? size : "13")}pt;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-family: "Lato";
  text-align: ${({ centered }) => {
    return centered ? "center" : "start";
  }};
  @media only screen and (max-width: 900px) {
    /* max-width: 300px; */
  }
`;

export const Banner = styled.div`
  background-color: ${colors.blue};
  padding: 40px;
`;

export const StyledTitle = styled.div<TitleProps>`
  font-family: ${({ altFont }) =>
    altFont ? "Lato" : "Bree Serif, sans-serif"};
  color: ${({ color }) => (color ? color : "#536275")};
  font-size: ${({ size }) => (size ? size : "25pt")};
  font-weight: ${({ weight }) => weight};
  width: ${({ full }) => (full ? "100%" : "")};
  max-width: ${({ full }) => (full ? "" : "500px")};
  line-height: 1em;
  margin-top: ${({ full }) => (full ? "0" : "0px")};
  margin-bottom: 20px;

  @media only screen and (max-width: 900px) {
    line-height: ${({ level }) => (level === 1 ? "55px" : "40px")};
    text-align: ${({ centered }) => {
      return centered ? "center" : "start";
    }};
  }

  @media only screen and (max-width: 478px) {
    font-size: 2em;
    line-height: 1em;
    margin-top: 30px;
    margin-bottom: 15px;
  }
`;

export const Title = ({
  centered,
  weight = 400,
  level = 1,
  children,
  color,
  alt,
  style,
  full,
}: TitleProps) => {
  return (
    <StyledTitle
      centered={centered}
      weight={weight}
      alt={alt}
      color={color}
      level={level}
      style={style}
      full={full}
    >
      {children}
    </StyledTitle>
  );
};

export const MapTitle = styled.h1`
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 10px;
  margin-top: -50px;
  width: 100%;
  background-color: white;
  z-index: 1200;
  padding: 19px;
  margin-left: -20px;
  width: 300px;

  @media only screen and (max-width: 900px) {
    margin: 0px;
    width: 100%;
    z-index: 1100;
  }
`;

export const Callout = styled("div")<{ type?: string; vertical?: boolean }>`
  display: flex;
  padding: 60px 50px;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ vertical }) => {
    return vertical ? "column" : "";
  }};
  background-color: ${({ type }) => {
    switch (type) {
      case "primary":
        return "#536275";
      case "white":
        return "#fff";
      case "grey":
        return "#f1f1f1";
      default:
        return "#536275";
    }
  }};
  @media only screen and (max-width: 991px) {
    flex-direction: column;
    padding: 20px;
    min-height: 200px;
  }
`;

export const StyledInput = styled.input`
  border: 1px solid #d4d4d4;
  font-family: "Lato";
  font-size: 12pt;
  padding: 20px;
  margin: 10px 0px;
`;

export const TextWrapper = styled.div`
  display: flex;
  width: 50%;
  min-width: 400px;
  padding: 50px 0px 50px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 900px) {
    padding-top: 0px;
  }
`;

export const CalloutContentWrapper = styled.div<{ vertical?: boolean }>`
  display: flex;
  flex: 1 0 auto;
  max-width: 90vw;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ vertical }) => (vertical ? "column" : "row")};
  @media only screen and (max-width: 900px) {
    max-width: 100%;
    flex-direction: column;
  }
`;

export const CalloutContentText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Pages = styled.div`
  /* display: block; */
  @media only screen and (max-width: 900px) {
  }

  @media only screen and (max-width: 767px) {
  }

  @media only screen and (max-width: 478px) {
  }
`;

export const FormButton = styled.input`
  ${button}
  @media only screen and (max-width: 900px) {
    border-radius: 0px;
  }
`;

export const DefaultButton = styled.div`
  ${button}
`;

export const LoginError = styled.p`
  color: red;
  text-align: center;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 60px;
  min-height: 495px;
  @media only screen and (max-width: 768px) {
    margin: 0px;
    height: 100%;
    min-height: 0px;
  }
`;

export const DefaultInput = styled.input`
  border: 1px solid #d4d4d4;
  font-family: "Lato";
  font-size: 12pt;
  padding: 20px;
  margin: 10px 0px;
`;

export const MDTModal = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: 60%;
  min-width: 600px;
  box-shadow: 5px 5px 20px 5px #80808021;
  color: ${colors.lightBlue};
  @media only screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    min-width: 0px;
    align-items: start;
    height: 100%;
    margin-left: 50px;
    box-shadow: none;
  }
`;

export const FormInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
`;

export const MDTForm = styled.form`
  display: flex;
  flex-direction: column;

  & .name {
    display: flex;
    justify-content: space-between;
  }

  & .name > input[type="text"] {
    flex: 1 1 auto;
    margin: 0px;
    margin-left: 20px;
    overflow: auto;
  }

  & input[type="password"],
  input[type="email"],
  input[type="text"] {
    border: 1px solid rgb(212, 212, 212);
    font-family: "Lato";
    font-size: 12pt;
    padding: 15px;
  }
  & input {
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  & input[type="submit"] {
    font-family: var(--fonts);
  }
`;

export const ModalTitle = styled.h1`
  font-size: 32px;
  margin: 20px;
  @media only screen and (max-width: 768px) {
    padding-left: 10px;
  }
`;

export const ContentWrapper = styled.div`
  padding: 0px 150px;
`;

export const ModalContent = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  font-size: 12pt;
  will-change: transform, opacity;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 0px;
  }
`;
