import React, { useContext, useState } from "react";
import { MobileNavbarButtons } from "./MobileNavbarButtons";
import NavIcon from "../../../images/mdt-turtle-logo.svg";
import Button from "../../Button/Button";
import { useSpring } from "@react-spring/core";
import styled from "styled-components";
import { MobileNav } from "../NavbarComponents";
import { navigate } from "gatsby";
import { DebrisTrackerContext } from "../../../context/DebrisTrackerContext";
import Hamburger from "./Hamburger";

const MobileNavLogo = () => (
  <img
    src={NavIcon}
    onClick={() => {
      navigate("/");
    }}
    style={{ width: "60px", height: "60px", margin: "20px" }}
    alt=""
  />
);

const MobileSignUp = () => (
  <div
    onClick={() => {
      navigate("sign-up");
    }}
    style={{ padding: "0px 10px", marginTop: "20px" }}
  >
    <Button style={{ width: "100%", padding: "10px 0px" }}>Sign Up</Button>
  </div>
);

const MobileMyAccount = () => (
  <div
    onClick={() => {
      navigate("/dashboard/user");
    }}
    style={{ padding: "0px 10px", marginTop: "20px" }}
  >
    <Button style={{ width: "100%", padding: "10px 0px" }}>My Account</Button>
  </div>
);

const LogoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin-left: 10px;
  font-size: 30px;
  font-size: 15pt;
`;

const MobileLogOut = () => (
  <LogoutWrapper>
    <MobileNav.Button>Log Out</MobileNav.Button>
  </LogoutWrapper>
);

const MobileNavbar = () => {
  const [showNavbar, setShowNavbar] = useState<boolean>(false);
  const { user, setUser } = useContext(DebrisTrackerContext);

  const toggleNavbar = () => {
    setShowNavbar((prev) => !prev);
  };

  const expand = useSpring({
    config: { duration: 250 },
    opacity: showNavbar ? 1 : 0,
    display: showNavbar ? "flex" : "none",
  });

  return (
    <MobileNav.Wrapper>
      <MobileNav.BannerWrapper>
        <MobileNavLogo />
        <Hamburger onClick={toggleNavbar} open={showNavbar} />
        <MobileNav.ItemsWrapper
          show={showNavbar ? "show" : undefined}
          style={expand}
        >
          <MobileNavbarButtons />
          {user && user.id ? <MobileMyAccount /> : <MobileSignUp />}
          {user && user.id && (
            <div
              onClick={() => {
                sessionStorage.removeItem("user");
                setUser(undefined);
              }}
            >
              <MobileLogOut />
            </div>
          )}
        </MobileNav.ItemsWrapper>
      </MobileNav.BannerWrapper>
    </MobileNav.Wrapper>
  );
};

export default MobileNavbar;
